import NextLink from 'next/link';

import { Box, Button, Container, Flex, IconButton, Link as ExternalLink, Tooltip } from '@chakra-ui/react';
import { FacebookCircle, Instagram, Tiktok, Twitter } from '@styled-icons/boxicons-logos';

import Logo from '@common/Logo';

import styles from '@styles/export.module.scss';

const footerButtons = [
  {
    label: 'Về chúng tôi',
    href: '/about-us',
  },
  {
    label: 'Các điều khoản dịch vụ',
    href: '/terms-and-conditions',
  },
  {
    label: 'Chính sách quyền riêng tư',
    href: '/privacy-policy',
  },
];

const socialButtons = [
  {
    name: 'Facebook',
    icon: FacebookCircle,
    url: 'https://www.facebook.com/sherdtrip',
  },
  {
    name: 'Instagram',
    icon: Instagram,
    url: 'https://www.instagram.com/sherdtrip',
  },
  {
    name: 'Twitter',
    icon: Twitter,
    url: 'https://twitter.com/sherdtrip',
  },
  {
    name: 'Tiktok',
    icon: Tiktok,
    url: 'https://www.tiktok.com/@sherdtrip',
  },
];

const Footer: React.FC = () => {
  return (
    <Flex as="footer">
      <Container
        display="flex"
        flexDirection="column"
        maxWidth="7xl"
        color={styles.colorPrimaryBlack}
        gap="4"
        position="relative"
        py="10"
        px="8"
        background={styles.colorPrimaryWhite}
      >
        <Logo height="40px" width="212px" withText />
        <Flex gap="4">
          {footerButtons.map((button) => (
            <NextLink key={button.label} href={button.href}>
              <Button variant="link" color={styles.colorPrimaryBlack} fontWeight="600" fontSize="16px">
                {button.label}
              </Button>
            </NextLink>
          ))}
        </Flex>
        <Box as="p">Bản quyền © 2022 Sherptrip.com . Bảo lưu mọi quyền</Box>
        <Box as="p">Liên lạc với chúng tôi thông qua: sherdtrip@email.com</Box>
        <Flex gap="4" position="absolute" bottom="0" right="8">
          {socialButtons.map((button) => (
            <Tooltip key={button.name} label={button.name} hasArrow>
              <ExternalLink href={button.url} target="_blank">
                <IconButton
                  aria-label={button.name}
                  icon={<button.icon size="24px" />}
                  variant="ghost"
                  borderRadius="full"
                />
              </ExternalLink>
            </Tooltip>
          ))}
        </Flex>
      </Container>
    </Flex>
  );
};

export default Footer;
